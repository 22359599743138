import * as React from 'react';
import AppBar from '../components/AppBar';
import Toolbar from '../components/Toolbar';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

import { auth } from "../firebase-config";
import { signInAnonymously,onAuthStateChanged, signOut } from "firebase/auth";


function AppAppBar() {

  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState();
  const [authUser, setAuthUser] = React.useState();

  const socialLoginMethod = ['Anonymous', 'Google', 'Facebook'];

  onAuthStateChanged(auth, (user) => {
    if (user) {
      setAuthUser(user);
    } else {
      // setAuthUser(null);
    }
  });

  const handleListItemClick = (value) => {
    setSelectedValue(value);
    setOpen(false);

    if(selectedValue === 'Anonymous'){
      signInAnonymously(auth).then((u) => {
        console.log(u);
       setAuthUser(u.user)
      })
      .catch((error) => {
        // ...
      });
    }
  };

  const LoginButtonView = () => {

    return (<Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
    {!authUser && <Button variant="outlined" onClick={() => setOpen(true)}>
    {'Social Login'}
    </Button>
    } 
    {authUser?.isAnonymous === true && 
     <Button variant="outlined" onClick={() =>{ signOut(auth); setAuthUser(null);}}>
     {'Logout (anonymous)'}
     </Button>}
  </Box>);
  }

  return (
    <div>
      <AppBar
          position="fixed"
          color="default"
          elevation={0}
          sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
        >
          <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ flex: 1 }} />
          <Link
            variant="h6"
            underline="none"
            color="inherit"
            href="/"
            sx={{ fontSize: 24 }}
          >
            {process.env.REACT_APP_APP_NAME}
          </Link>
          <LoginButtonView />
          
          </Toolbar>
          <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>Socialmedia login with</DialogTitle>
            <List sx={{ pt: 0 }}>
              {socialLoginMethod.map((method) => (
                <ListItem button onClick={() => handleListItemClick(method)} key={method}>
                  {/**
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                      <PersonIcon />
                    </Avatar>
                  </ListItemAvatar>
                   */}
                  <ListItemText primary={method} />
                </ListItem>
              ))}
            </List>
          </Dialog>
        </AppBar>
        <Box sx={{marginTop: 8}}></Box>
    </div>
  );
}

export default AppAppBar;
