import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import DATA from '../example.json';

export const fetchAllProducts = createAsyncThunk(
  'products/fetchAllProducts',
  async (_, thunkAPI) => {
    return DATA;
  }
);

export const fetchAllFilters = createAsyncThunk(
  'products/fetchAllFilters',
  async (_, thunkAPI) => {
    return DATA;
  }
);

function GroupByCategory(products) {
  return products.reduce((group, product) => {
    const { category } = product.item;
    group[category] = group[category] ?? [];
    group[category].push(product);
    return group;
  }, {});
}

function GetFilters(products) {
  const filterList = [];
  products.forEach(element => {
        if(element.item.filters.length > 0) {
            element.item.filters.forEach(e => {
              if(filterList.findIndex( x => x === e) === -1){
                filterList.push(e); // {name: e, isActive: false}
              }
            });
        }
      });
  return filterList.sort();
}

const initialState = {
  originItems: [],
  items: [],
  itemDetail: null,
  preBasket: [],
  preBasketCount: 1,
  basket: [],
  filters: [],
  activeFilters: []
};

const productsSlice = createSlice({
  name: 'products',
  initialState: initialState,
  reducers: {
    prepareItemDetails: (state, action) => {
      
      // reset
      state.preBasketCount = 1;
      state.itemDetail = null;
      state.preBasket = [];

      const productItemId = action.payload.id;
      var item = state.originItems.find(x => x.item.id === productItemId);
      
      if(item !== undefined)
        state.itemDetail = item;
      else{
        console.error("prepareItemDetails not successful", action.payload);
        console.error("state.originItems", state.originItems.length);
      }
    },
    addOrRemovePreBasket: (state, action) => {
      const data = action.payload;
      if(data.isCheckboxTyp){
        const idx = state.preBasket.findIndex(x => x.additionalIdx === data.additionalIdx && x.valueIdx === data.valueIdx );
        if(idx === -1){
          state.preBasket.push(data);
        } else {
          state.preBasket = state.preBasket.filter( x => !(x.additionalIdx === data.additionalIdx && x.valueIdx === data.valueIdx));
        }
      }else {
        const idx = state.preBasket.findIndex(x => x.additionalIdx === data.additionalIdx);
        if(idx === -1){
          state.preBasket.push(data);
        } else {
          state.preBasket = state.preBasket.filter( x => !(x.additionalIdx === data.additionalIdx));
          state.preBasket.push(data);
        }
      }
      
    },
    increasePreBasketCount: (state) => {
      state.preBasketCount += 1;
    },
    decreasePreBasketCount: (state) => {
      state.preBasketCount -= 1;
    },
    addToLocalStorageBasket: (state) => {

      function sumOfpreBasket() {
        let sum = state.itemDetail.item.basePrice;
    
        state.preBasket.forEach(item => {
          sum += item.extraAmount;
        });
    
        return (sum * state.preBasketCount);
      }

      const item = {
        item: state.itemDetail,
        selectedAdditionals: state.preBasket,
        count: state.preBasketCount,
        total: sumOfpreBasket()
      }

      state.basket.push(item);

      if(localStorage.getItem('basket') == null){

        const basket = [];
        basket.push(item)

        localStorage.setItem('basket', JSON.stringify(basket));
      }else {
        const basket = JSON.parse(localStorage.getItem('basket'));
        basket.push(item);
        localStorage.setItem('basket', JSON.stringify(basket));
      }

      // reset
      state.preBasketCount = 1;
      state.itemDetail = null;
      state.preBasket = [];
    },
    toggleFilter: (state, action) => {
      const data = action.payload;

      const idx = state.activeFilters.findIndex(x => x === data);
      if(idx === -1){
        state.activeFilters.push(data);
      } else {
        state.activeFilters = state.activeFilters.filter( x => !(x === data));
      }

      if(state.activeFilters.length > 0){
        const productItems = [];
        // filter items
        state.originItems.forEach(element => {
          var hasFilter = element.item.filters.includes(state.activeFilters.toString());
          if(hasFilter){
            productItems.push(element);
          }
        });

        state.items = GroupByCategory(productItems);
      }else {
        state.items = GroupByCategory(state.originItems);
      }
     
    }
  },

  extraReducers: (builder) => {
    builder.addCase(fetchAllProducts.fulfilled, (state, action) => {
      const payload = action.payload;

      state.items = GroupByCategory(payload);
      state.originItems = payload;
      state.filters = GetFilters(payload);

    })
    builder.addCase(fetchAllFilters.fulfilled, (state, action) => {
      state.filters = GetFilters(action.payload);
    })
  }
});

export const { prepareItemDetails, selectItemDetailValues, addOrRemovePreBasket, addToLocalStorageBasket, toggleFilter, increasePreBasketCount, decreasePreBasketCount } = productsSlice.actions
export default productsSlice.reducer