import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  orderTyp: "",
  orderTime: "",
  fullname: "",
  email: "",
  tel: "",
  remarks: "",
  street: "",
  streetNumber: "",
  city: "",
  paymentTyp: "Barzahlung",
  transactionId: "",
  cashPaymentId: ""
};


const orderSlice = createSlice({
    name: 'order',
    initialState: initialState,
    reducers: {
      updateOrder: (state, action) => {
        const data = action.payload;
        state.orderTyp = data.orderType;
        state.transactionId = data.transactionId;
        state.cashPaymentId = data.cashPaymentId;
      },
      updatePersonalDetails: (state, action) => {
        const data = action.payload;

        state.fullname = data.fullname;
        state.email = data.email;
        state.tel = data.tel;
        state.remarks = data.remarks;
      },
      updatePersonalDetailsRemarks: (state, action) => {
        state.remarks = action.payload;
      },
      updatePersonalDetailsFullname: (state, action) => {
        state.fullname = action.payload;
      },
      updatePersonalDetailsEmail: (state, action) => {
        state.email = action.payload;
      },
      updatePersonalDetailsTel: (state, action) => {
        state.tel = action.payload;
      },
      updatePaymentMethod: (state, action) => {
        state.paymentTyp = action.payload;
      },
      updateDeliverytime: (state, action) => {
        state.orderTime = action.payload;
      },
      updateStreet: (state, action) => {
        state.street = action.payload;
      },
      updateStreetNumber: (state, action) => {
        state.streetNumber = action.payload;
      },
      updateCity: (state, action) => {
        state.city = action.payload;
      },
      updateDeliveryAddress: (state, action) => {

        const data = action.payload;
        state.street = data.street;
        state.streetNumber = data.streetNumber;
        state.zipcode = data.zipcode;
        state.city = data.city;
      },
      completeOrder: (state) => {
        state.orderTyp = "";
        state.orderTime = "";
        state.firstname = "";
        state.lastname = "";
        state.email = "";
        state.tel = "";
        state.remarks = "";
        state.street = "";
        state.streetNumber = "";
        state.zipCode = "";
        state.city = "";
        state.transactionId = "";
      },
      resetCheckoutState: (state) => {
        state = initialState;
      }
    }
  });
  
  export const { updateOrder, 
                 updatePersonalDetails,
                 updateDeliveryAddress, 
                 updatePersonalDetailsRemarks,
                 completeOrder,
                 updatePersonalDetailsFullname,
                 updatePersonalDetailsEmail,
                 updatePaymentMethod,
                 updateDeliverytime,
                 updateStreet,
                 updateStreetNumber,
                 updateCity,
                 updatePersonalDetailsTel,
                 resetCheckoutState } = orderSlice.actions
  export default orderSlice.reducer