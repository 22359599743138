import './App.css';
import * as React from 'react';
import AppRoute from './AppRoutes';
import { Suspense } from "react";

function App() {
  return (
    <Suspense fallback={null}>
      <AppRoute />
    </Suspense>
  );
}

export default App;
