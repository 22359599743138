import * as React from 'react';
import withRoot from '../withRoot';
import AppAppBar from '../views/AppAppBar';
import ProductHome from '../views/ProductHome';
import ProductItem from '../views/ProductItem';
import AppFooter from '../views/AppFooter';

function Home() {
  return (
    <React.Fragment>
      <AppAppBar />
      <ProductHome />
      <ProductItem />
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(Home);

