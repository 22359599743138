import * as React from 'react';
import {Box, Button, Paper, Typography} from '@mui/material';

import withRoot from '../withRoot';
import AppAppBar from '../views/AppAppBar';
import AppFooter from '../views/AppFooter';
import { Container } from '@mui/system';
import { useSearchParams } from 'react-router-dom';
import { collection } from "firebase/firestore"; 
import Grid from '@mui/material/Unstable_Grid2';
import { currency } from '../common/formating';
import { firebaseDatabase} from "../firebase-config"; 
import { useTranslation } from "react-i18next";

import axios from 'axios';
import { query, where, limit, getDocs } from "firebase/firestore";  

function CheckoutSuccess() {
    
    const [searchParams] = useSearchParams();
    const datatransTrxId = searchParams.get('datatransTrxId');
    const cashPaymentId = searchParams.get('cashPaymentId');
    const { t } = useTranslation();

    const [overviewData, setOverviewData] = React.useState();

    React.useEffect(() => {

        if(datatransTrxId === null || datatransTrxId === undefined || datatransTrxId?.length === 0)
            return;

        axios.get('https://api.sandbox.datatrans.com/v1/transactions/'+datatransTrxId, {
            auth: {
                username: process.env.REACT_APP_DATATRANS_USERNAME,
                password: process.env.REACT_APP_DATATRANS_PASSWORD
            }
        }).then(res => {
            if(res.data.status === 'settled' || res.data.status === 'transmitted'){
                // submitSuccessfulOrder()
                console.log('submitSuccessfulOrder')
            }else {
                // show error and try again
                console.log('transaction is not in correct state')
            }
            console.log('response', res);
        }).catch((err) => {
          console.log(err);
        })
    }, [datatransTrxId]);


    React.useEffect(() => {
        if(!cashPaymentId)
            return;

        const ordersRef = collection(firebaseDatabase, "orders");
        const q = query(ordersRef, where("cashPaymentId", "==", cashPaymentId), limit(1));

        getDocs(q).then(querySnapshot => {
            querySnapshot.forEach((doc) => {
                setOverviewData(doc.data());
            });
        });
    }, [cashPaymentId])

    function OrderOverview() {

        return (<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{m: 2}}>
            <Grid xs={6}>
                <Typography>Bestellungsart</Typography>
            </Grid>
            <Grid xs={6}>
                {t(overviewData.orderTyp)}
            </Grid>
            <Grid xs={6}>
                <Typography>Zahlungsmethode</Typography>
            </Grid>
            <Grid xs={6}>
                {overviewData.paymentTyp}
            </Grid>
            <Grid xs={6}>
                <Typography>Referenz</Typography>
            </Grid>
            <Grid xs={6}>
                {overviewData.cashPaymentId}
            </Grid>
            <Grid xs={6}>
                <Typography>Zeit</Typography>
            </Grid>
            <Grid xs={6}>
                {overviewData.orderTime.length > 15 ? overviewData.orderTime : overviewData.orderTime + ' Uhr'}
            </Grid>
            <Grid xs={6}>
                <Typography>Betrag</Typography>
            </Grid>
            <Grid xs={6}>
                {currency(overviewData.total)}
            </Grid>
        </Grid>)
    }

    return (
        <React.Fragment>
            <AppAppBar />
            <Box sx={{marginTop: 12}}></Box>
            <Container maxWidth="sm" sx={{ my: { xs: 3, md: 6 }, p: { xs: 0, md: 0 }}}>
                <Paper sx={{my: { xs: 3, md: 6 },p: { xs: 3, md: 3 }}}>
                <Typography align='center'>Bestellung abgeschlossen :)</Typography>

                {overviewData && <OrderOverview />}
                <Button href="/" variant='contained' sx={{m: 3}}>Züruck zur Startseite</Button>
                </Paper>
            </Container>
            <AppFooter />
          </React.Fragment>);
}

export default withRoot(CheckoutSuccess);
