import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Button, Tooltip } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useDispatch, useSelector } from 'react-redux'
import Divider from '@mui/material/Divider';
import { removeItemFromBasekt } from '../features/basketSlice';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import {currency} from './../common/formating';
import Switch from '@mui/material/Switch';
import { updateOrderType } from '../features/deliverySlice';
import DeliveryZipcodeModal from './DeliveryZipcodeModal';
import { useTranslation } from "react-i18next";

export default function BasketView(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const basket = useSelector((state) => state.basket.basket);
    const subtotal = useSelector((state) => state.basket.subtotal);
    const isDelivery = useSelector((state) => state.delivery.isDelivery);
    const minimumOrderValue = useSelector((state) => state.delivery.minimumOrderValue);
    const orderType = useSelector((state) => state.delivery.orderType);
    
    const total = subtotal;

    const remindingAmount = minimumOrderValue - subtotal;
    const allowToOrder = () => isDelivery ? remindingAmount <= 0 : total > 0;

    return(
      <Paper elevation={1} sx={{minHeight: '100%', maxWidth: '100%', p: { xs: 3, md: 3, sm: 3 }}}>
        <Stack direction="row" spacing={1} sx={{border: '1px dashed grey', p: 2, mb: 3}}>
        <Grid container spacing={1.5}>
            <Grid item xs={9}>
                <Typography gutterBottom variant="subtitle1" component="div" sx={{fontWeight: 'bold'}}>
                {t('DELIVERY')}
                </Typography>
            </Grid>
            <Grid item xs={3}>
            {isDelivery && <Tooltip title="auf Abholdung umschalten"><Switch value={'DELIVERY'} defaultChecked={true} onChange={ () => dispatch(updateOrderType(orderType === 'DELIVERY' ? 'PICKUP' : 'DELIVERY'))} /></Tooltip> }
            {!isDelivery && <Tooltip title="auf Lieferung umschalten"><Switch value={'DELIVERY'} defaultChecked={false} onChange={ () => dispatch(updateOrderType(orderType === 'DELIVERY' ? 'PICKUP' : 'DELIVERY'))} /></Tooltip> }
            </Grid>
        </Grid>
            </Stack>
        <Stack sx={{position: 'sticky', top: '130px'}}>
          
          <Typography variant="h5" sx={{textAlign: 'center', fontWeight: 'bold'}}>{t('SHOPPING_CART')}</Typography>

          {basket.map((item, index) => (
            <Grid key={index} container spacing={1.5}>
                <Grid key={"count" +index} item xs={1}>
                    <Typography gutterBottom variant="subtitle1" component="div" sx={{fontWeight: 'bold'}}>
                    {item.count}
                    </Typography>
                </Grid>

                <Grid item xs={7}>
                    <Typography key={"typo" + index} gutterBottom variant="subtitle1" component="div" sx={{fontWeight: 'bold'}}>
                    {item.item.item.name}
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography gutterBottom variant="subtitle1" component="div">
                    {currency(item.total)}
                    </Typography>
                </Grid>

                <Grid item xs={1}>
                  <IconButton aria-label="delete" onClick={() => dispatch(removeItemFromBasekt(index))}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
            </Grid>
          ))} 

        <Stack>
          {isDelivery && remindingAmount > 0 && 
          <Typography>
            {t('MSG_VALUE_NOT_MATCHED', {amount: currency(remindingAmount)})}
        </Typography>}
          
        </Stack>

        <Divider variant="middle" />
        <Stack>
          <Grid container spacing={1.5}>
            <Grid item xs={8}>
                <Typography gutterBottom variant="subtitle1" component="div">
                {t('SUBTOTAL')}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography gutterBottom variant="subtitle1" component="div">
                {currency(subtotal)}
                </Typography>
            </Grid>
          </Grid>

          {isDelivery && 
          <Grid container spacing={1.5}>
          <Grid item xs={8}>
              <Typography gutterBottom variant="subtitle1" component="div">
              {t('DELIVERY_CHARGE')}
              </Typography>
          </Grid>
          <Grid item xs={4}>
              <Typography gutterBottom variant="subtitle1" component="div">
              {t('FOR_FREE')}
              </Typography>
          </Grid>
        </Grid>}
          

          <Grid container spacing={1.5}>
            <Grid item xs={8}>
                <Typography gutterBottom variant="subtitle1" component="div" sx={{fontWeight: 'bold'}}>
                {t('SUM')}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography gutterBottom variant="subtitle1" component="div" sx={{fontWeight: 'bold'}}>
                {currency(subtotal)}
                </Typography>
            </Grid>
          </Grid>
        </Stack>
        
        {!props.hideActionButton && <Button variant="contained" disabled={!allowToOrder()} href="/checkout">
          {t('PAY', {amount: currency(total)})}
        </Button>}
        </Stack>
        {isDelivery && <DeliveryZipcodeModal />}
      </Paper>
    );
  }
