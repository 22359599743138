import * as React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, CardActions, Container } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useDispatch, useSelector } from 'react-redux'
import {prepareItemDetails} from '../features/productsSlice';

import ProductFilter from './ProductFilter';
import BasketView from './Basket';
import ProductCategories from './ProductCategories';
import { addToBasket } from '../features/basketSlice';
import ProductItemDetailModal from './ProductItemDetailModal';
import IconButton from '@mui/material/IconButton';
import {currency} from './../common/formating';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';


function ProductItem() {
  const dispatch = useDispatch();
  const detail = useSelector((state) => state.products.itemDetail);

  const [open, setOpen] = React.useState(false);

  const handleOpenModalView = (product) => {
    
    if(product.additionals?.length > 0 ){
      dispatch(prepareItemDetails({id: product.item.id}));
      setOpen(true);
    }else {
     dispatch(addToBasket({item: product, count: 1, selectedAdditionals: [], total: product.item.basePrice}))
    }
  };

  function ProductItemsView() {

    const products = useSelector((state) => state.products.items);

    return Object.keys(products).map(key => 
      <div key={key} id={encodeURI(key)}>
        <Typography gutterBottom variant="h6" component="div" sx={{paddingLeft: { xs: 2, sm: 0 }}} >
          {key}
        </Typography>
        <Grid container spacing={1.5} sx={{marginBottom: 5, paddingLeft: { xs: 2, sm: 0 }}} columns={{ xs: 4, sm: 8, md: 12 }}>
          {products[key].map((product, index) => (
            <Grid item xs={4} key={index}>
              <Card sx={{ maxWidth: 355 }} onClick={() => handleOpenModalView(product)}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="290"
                    sx={{objectFit: 'fill'}}
                    src={product.item.imageUrl}
                    alt={product.item.name}
                  />
                  <CardContent>
                    <Stack direction="row" spacing={2} justifyContent="space-between">
                      <Typography gutterBottom variant="h6" component="div" sx={{fontSize: {sm: '0.9vw'}}}>
                        {product.item.name}
                      </Typography>
                      
                      <Typography gutterBottom variant="h6" component="div" sx={{fontSize: {sm: '0.9vw'}}}>
                        {currency(product.item.basePrice)}
                      </Typography>
                    </Stack>
                      
                    <Typography variant="body2" color="text.secondary" noWrap>
                      {product.item.description}
                    </Typography>
                  </CardContent>
                    <CardActions>
                    <IconButton aria-label="shopping">
                      <ShoppingBagIcon />
                    </IconButton>
                  </CardActions>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>

    </div>
    );
  }


  return (<Container variant="outlined" maxWidth="false" sx={{ my: { xs: 3, md: 6, sm: 3 }, p: { xs: 0, md: 0, sm: 0 }}}>
            <Stack sx={{position: 'sticky', top: {xs: '64px', md: '70px'}, zIndex: 1}}>
              <ProductCategories />
            </Stack>

            <Grid container spacing={1.5} columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={2}>
                <ProductFilter />
              </Grid>
              <Grid item xs={7}>
                <ProductItemsView />
                {/** Modal */}
                {detail && <ProductItemDetailModal isOpen={open} isOpenCallback={(flag) => setOpen(flag)} />}
              </Grid>
              <Grid item xs={3}>
                <BasketView hideActionButton={false} />
              </Grid>
            </Grid>
          </Container>);
  }

export default ProductItem;
