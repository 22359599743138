import * as React from 'react';
import { Routes, Route } from "react-router-dom";

import Home from './features/Home';
import Checkout from './features/Checkout';
import NotFound from './features/NotFound';
import CheckoutError from './features/CheckoutError';
import CheckoutSuccess from './features/CheckoutSuccess';
import CheckoutCancel from './features/CheckoutCancel';
import Receipt from './features/Receipt';

function AppRoute() {
    return (
      <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='checkout' element={<Checkout />} />
          <Route exact path='receipt' element={<Receipt />} />
          <Route exact path='checkout-error' element={<CheckoutError />} />
          <Route exact path='checkout-success' element={<CheckoutSuccess />} />
          <Route exact path='checkout-cancel' element={<CheckoutCancel />} />
          <Route path="*" element={<NotFound/>}/>
        {/**
         * <Route path="/" element={<AppLayout />}>
          <Route path="payment" element={<Payment />} />
        </Route>
         */}
      </Routes>
    );
  }
  
  export default AppRoute;
  