import * as React  from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { updateOrderType, updateZipcode } from '../features/deliverySlice';
import deliveryZone from './../deliveryzone.json'
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from 'react-redux';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
  };


function DeliveryZipcodeModal(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const defaultProps = {
        options: deliveryZone,
        getOptionLabel: (option) => option.zipcode,
      };

    const zipcode = useSelector((state) => state.delivery.zipcode);
    const [openModal, setOpenModal] = React.useState(true);
    const inputZipcodeRef = React.useRef();

    const handleCloseModal = () => {
        setOpenModal(false);
        if(!inputZipcodeRef?.current?.value){
            //dispatch(updateOrderType('PICKUP'))
        }
    };

    const handleCloseModalWithFallback = () => {
        setOpenModal(false);
        if(!inputZipcodeRef?.current?.value){
            dispatch(updateOrderType('PICKUP'))
        }
    };

    React.useEffect(() => {
        if(zipcode != null){
            handleCloseModal();
        }
    }, [zipcode])
    
    return (<Modal
    open={openModal}
    onClose={handleCloseModalWithFallback}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    >
    <Box sx={style}>
    <Typography variant="h6" component="div">
        {t('ZIP_SELECT')}
    </Typography>

    <Autocomplete
        {...defaultProps}
        id="select-zipcode"
        sx={{ width: 300, color: '#FFF', m: 4, mr: 0, ml: 0 }}
        options={deliveryZone}
        autoHighlight
        defaultValue={zipcode}
        onChange={(e) => dispatch(updateZipcode(e.target.value))}
        getOptionLabel={(option) => option.zipcode }
        clearOnEscape
        renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            {option.zipcode}
        </Box>
        )}
        renderInput={(params) => (
        <TextField
            {...params}
            inputRef={inputZipcodeRef}
            label={t('ZIP_CODE')}
            inputProps={{
            ...params.inputProps,
            }}
        />
        )}
        />
        <Button size="large" variant="outlined" color="primary" disabled={zipcode == null} onClick={handleCloseModal}>
        {t('SAVE_CLOSE')}
        </Button>
    </Box>
    </Modal>);
}

export default DeliveryZipcodeModal;