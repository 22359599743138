import * as React from 'react';
import Box from '@mui/material/Box';

import Grid from '@mui/material/Grid';
import withRoot from '../withRoot';
import AppAppBar from '../views/AppAppBar';
import AppFooter from '../views/AppFooter';
import { Container } from '@mui/system';
import BasketView from '../views/Basket';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '../components/Typography';
import { Button, Paper, InputLabel, MenuItem } from '@mui/material';
import { currency } from '../common/formating';
import { useDispatch, useSelector } from 'react-redux'
import FormControl from '@mui/material/FormControl';
import Lightbox from 'react-datatrans-light-box';
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { addDoc, collection } from "firebase/firestore"; 
import { resetCheckoutState } from './orderSlice' 
import { removeAllFromBasket } from './basketSlice'
import { firebaseDatabase} from "../firebase-config"; 
import { useTranslation } from "react-i18next";
import deliveryTime from "./../deliverytime.json";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import axios from 'axios';
import { urlAlphabet, customAlphabet } from 'nanoid'

import { updateOrder,
         updateDeliverytime,
         updatePersonalDetails,
         updateDeliveryAddress } from './orderSlice' 
import InfoModal from '../views/InfoModal';

function Checkout() {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const subtotal = useSelector((state) => state.basket.subtotal);
    const isDelivery = useSelector((state) => state.delivery.isDelivery);
    const orderType = useSelector((state) => state.delivery.orderType);
    const zipcode = useSelector((state) => state.delivery.zipcode);
    const navigate = useNavigate();
    const nanoid = customAlphabet(urlAlphabet, 9)

    const [transactionId, setTransactionId] = React.useState(230205184226717082);
    const [lightbox, showLightbox] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    const onLoaded = () => console.log('Loaded')
    const onOpened = () => console.log('Opened')
    const onCancelled = () => { showLightbox(false); }
    const onError = (data) => {
        console.log('Error:', data)
        showLightbox(false)
    }

    const total = subtotal;
    const { handleSubmit, control, formState: { isValid } } = useForm();
    //const timeList = ['So schnell wie möglich', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00'];
    const [timeList, setTimeList] = React.useState();

    const [isBusinessClosed, setIsBusinessClosed] = React.useState(false);
    const paymentMethodRef = React.useRef();

    const basket = useSelector((state) => state.basket.basket);

    React.useEffect(() => {
        /**
         * no need anymore
        if(paymentMethodRef.current?.value !== 'Barzahlung'){
            const requestBody = {
                currency: "CHF",
                refno: nanoid(),
                amount: total * 100,
                paymentMethods: [getPaymentMethodCode()],
                redirect: {
                    successUrl: process.env.REACT_APP_DATATRANS_PAYMENT_SUCCESS_URL,
                    cancelUrl: process.env.REACT_APP_DATATRANS_PAYMENT_CANCEL_URL,
                    errorUrl: process.env.REACT_APP_DATATRANS_PAYMENT_ERROR_URL
                }
            };
            axios.post(process.env.REACT_APP_DATATRANS_PAYMENT_URL, requestBody, {
                auth: {
                  username: process.env.REACT_APP_DATATRANS_USERNAME,
                  password: process.env.REACT_APP_DATATRANS_PASSWORD
                }
              })
                .then(response => {
                    console.log('transactionId', response.data.transactionId)
                    setTransactionId(response.data.transactionId);
                });
 */

                /**
                 * 
  const postData = {
    currency: "CHF",
    refno: requestBody.referenceNumber,
    amount: requestBody.Amount,
    redirect: {
      successUrl: requestBody.successUrl,
      cancelUrl: requestBody.cancelUrl,
      errorUrl: requestBody.errorUrl
    }
  };

  axios.post("https://api.sandbox.datatrans.com/v1/transactions", postData, {
    auth: {
      username: "1110003085",
      password: "39XDRUkMOdanXnr1"
    }
  }).then((res) => {
    functions.logger.info(res.data);
    response.send({transactionId: res.data.transactionId});
  });
                
        } */
    }, [total, paymentMethodRef]);

    function checkBusinessOpening(todayDeliveryTime){
        setIsBusinessClosed(true);
        const current = new Date();

        for (let i = 0; i < todayDeliveryTime.times.length; i++) {
            const element = todayDeliveryTime.times[i];
            
            let dateStr = current.getFullYear();

            if((current.getMonth() + 1) <= 9){
                dateStr += '-0' + (current.getMonth() + 1);
            }else {
                dateStr += '-' + (current.getMonth() + 1);
            }

            if(current.getDate() <= 9){
                dateStr += '-0' + current.getDate();
            }else {
                dateStr += '-' + current.getDate();
            }

            const start = new Date(dateStr+' ' + element.startTime);
            const end = new Date(dateStr + ' ' + element.endTime);
            
            if(current.getTime() >= start.getTime() && current.getTime() <= end.getTime()) {
                setIsBusinessClosed(false);
            }
        }
    }

    React.useEffect(() => {
        const day = Date().toLocaleString().split(' ')[0].toUpperCase();
        var today = deliveryTime.find( x => x.day.toUpperCase() === day);
        checkBusinessOpening(today);
        prepareTimelist(today)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    
    const prepareTimelist = (today) => {
        if(isBusinessClosed)
            return;
        
        const timeList2 = ['So schnell wie möglich'];

        const current = new Date();
        for (let i = 0; i < today.times.length; i++) {
            const element = today.times[i];
            
            let dateStr = current.getFullYear();

            if((current.getMonth() + 1) <= 9){
                dateStr += '-0' + (current.getMonth() + 1);
            }else {
                dateStr += '-' + (current.getMonth() + 1);
            }

            if(current.getDate() <= 9){
                dateStr += '-0' + current.getDate();
            }else {
                dateStr += '-' + current.getDate();
            }

            const start = new Date(dateStr+' ' + element.startTime);
            const end = new Date(dateStr + ' ' + element.endTime);
            
            if(current.getTime() >= start.getTime() && current.getTime() <= end.getTime()) {
                
                // prepare list
                const start = current.getHours(); // element.startTime.split(':')[0];
                const end = element.endTime.split(':')[0];
                
                for (let x = start; x < end; x++) {
                    if (x === start){
                        if(current.getMinutes() <= 0){
                            timeList2.push(x + ':00'); 
                        }
                        if(current.getMinutes() <= 20){
                            timeList2.push(x + ':20'); 
                        }
                        if(current.getMinutes() <= 40){                        
                            timeList2.push(x + ':40'); 
                        }
                    }else {
                        timeList2.push(x + ':00'); 
                        timeList2.push(x + ':20'); 
                        timeList2.push(x + ':40'); 
                    }
                }

                timeList2.push(element.endTime); 
                break;
            }
        }
        setTimeList(timeList2);
    }

    function createTransactionId(data){
        if(paymentMethodRef.current?.value !== 'Barzahlung'){
            
            const requestBody = {
                referenceNumber: nanoid(),
                amount: total * 100,
                successUrl: process.env.REACT_APP_DATATRANS_PAYMENT_SUCCESS_URL,
                cancelUrl: process.env.REACT_APP_DATATRANS_PAYMENT_CANCEL_URL,
                errorUrl: process.env.REACT_APP_DATATRANS_PAYMENT_ERROR_URL,
                paymentMethods: [getPaymentMethodCode(data)]
            };
            axios.post(process.env.REACT_APP_FIREBASE_FUNCTIONS_CREATE_TRANSACTION_ID, requestBody)
                .then(res => {
                    setTransactionId(res.data.transactionId);
                    initiatePaymentTransaction(res.data.transactionId);
                });
            
           /*** 
            const requestBody = {
                currency: "CHF",
                refno: nanoid(),
                amount: total * 100,
                paymentMethods: [getPaymentMethodCode(data)]
            };
            axios.post(process.env.REACT_APP_DATATRANS_PAYMENT_URL, requestBody, {
                auth: {
                  username: process.env.REACT_APP_DATATRANS_USERNAME,
                  password: process.env.REACT_APP_DATATRANS_PASSWORD
                },
                mode: 'no-cors',
                headers: {
                    'Access-Control-Allow-Origin': '*'
                }
              })
                .then(response => {
                    console.log('transactionId', response.data.transactionId)
                    setTransactionId(response.data.transactionId);

                    initiatePaymentTransaction();
                });

            ***/
        }
    }   


    function getPaymentMethodCode(data) {
        var selectedPayment = data.paymentTyp;
        switch (selectedPayment) {
            case "Visa":
                return "VIS"
            case "MasterCard":
                return "ECA"
            case "PostFinance Card":
                return "PFC"
            case "PostFinance E-Finance":
                return "PEF"
            case "Twint":
                return "TWI"
            default:
                return ""
        }
    }

    function initiatePaymentTransaction(trxId) {
        const order = {
            orderType: orderType,
            transactionId: trxId
        };
        dispatch(updateOrder(order));
        showLightbox(true);
    }

    async function initiateCashPaymentTransaction(data) {
        console.log('initiateCashPaymentTransaction')
        const order = {
            orderType: orderType,
            cashPaymentId: nanoid()
        };
        dispatch(updateOrder(order));

        await submitSuccessfulOrder(data, order.cashPaymentId);

        navigate('/checkout/success?cashPaymentId='+order.cashPaymentId, { replace: true })
    }

    async function submitSuccessfulOrder(data, cashPaymentId){
       
        const basketItem = basket;

        var newObject = {};
        if(isDelivery){
            newObject = {
                fullname: data.fullname,
                email: data.email,
                tel: data.tel,
                remarks: data.remarks,
                orderTyp: orderType,
                orderTime: data.orderTime,
                paymentTyp: data.paymentTyp,
                cashPaymentId: cashPaymentId,
                total: subtotal,
                delivery: {
                    street: data.street,
                    streetNumber: data.streetNumber,
                    zip: data.zipcode,
                    city: data.city
                },
                items: basketItem
            };
        }else {
            newObject = {
                fullname: data.fullname,
                email: data.email,
                tel: data.tel,
                remarks: data.remarks,
                orderTyp: orderType,
                orderTime: data.orderTime,
                paymentTyp: data.paymentTyp,
                cashPaymentId: cashPaymentId,
                total: subtotal,
                items: basketItem
            };
        }

        const docRef = await addDoc(collection(firebaseDatabase, "orders"), newObject);
        if(docRef.id.length > 0){
            sendMail2Customer(newObject);
            sendMail2Restaurant(newObject);
        }
    }


    function sendMail2Customer(data) {
        
        let html = '';
        html += 'Sehr geehrte Damen und Herren <br /><br />';

        html += 'Referenz: '+ data.cashPaymentId + '<br />';
        html += 'Zeit: '+ data.orderTime + '<br />';
        html += 'Zahlungsmethode: '+ data.paymentTyp + '<br />';
        html += 'Bestellungsart: '+ t(data.orderTyp) + '<br />';
        
        if(data.delivery){
            html += '<br />Lieferadresse: <br />'
            html += 'Name: '+ data.fullname + '<br />';
            html += 'Adresse: '+ data.delivery.street + ' ' + data.delivery.streetNumber +', ' + data.delivery.zip + ' ' + data.delivery.city +'<br />';
        }

        html += '<br />Bestellung:<br />';
        data.items.forEach(element => {
            html += element.count +'x ' + element.item.item.name + ' -- ' + currency(element.total) + '<br />';
        });

        html += '<br /><br />';
        html += '<b>Gesamtbetrag: ' + currency(data.total) + '</b>'

        const mailPayload = {
            to: data.email,
            message: {
                subject: 'Dasamy.com - Bestellung Bestätigung',
                html: html
            }
        }
        console.log('mailPAylod', mailPayload);
        sendMail(mailPayload);
    }

    function sendMail2Restaurant(data) {
        let html = '';
        html += '<br /><br />'
        html += 'Referenz: '+ data.cashPaymentId + '<br />';
        html += 'Zeit: '+ data.orderTime + '<br />';
        html += 'Zahlungsmethode: '+ data.paymentTyp + '<br />';
        html += 'Bestellungsart: '+ t(data.orderTyp) + '<br />';
        
        html += '<br />Persönliche daten: <br />'
        html += 'Name: '+ data.fullname + '<br />';
        html += 'Tel: '+ data.tel + '<br />';
        html += 'Email: '+ data.email + '<br />';
        
        if(data.delivery){
            html += '<br />Lieferadresse: <br />'
            html += 'Name: '+ data.fullname + '<br />';
            html += 'Adresse: '+ data.delivery.street + ' ' + data.delivery.streetNumber +', ' + data.delivery.zip + ' ' + data.delivery.city +'<br />';
        }

        html += '<br />Bestellung:<br />';
        data.items.forEach(element => {
            html += element.count +'x ' + element.item.item.name + ' -- ' + currency(element.total) + '<br />';
        });

        html += 'Anmerkungen: ' + data.remarks + '<br />';

        html += '<br /><br />';
        html += '<b>Gesamtbetrag: ' + currency(data.total) + '</b>'
        html += '<br /><br />';
        html += '<b>Quittung: <a href="dasamy://'+ data.cashPaymentId + '">Drucken</a></b>'

        const mailPayload = {
            to: 'order@dasamy.com',
            message: {
                subject: 'Neue Bestellung',
                html: html
            }
        }

        sendMail(mailPayload);

        // Clear State form the redux store
        ClearState();
    }

    function ClearState(){
        dispatch(removeAllFromBasket());
        dispatch(resetCheckoutState());
    }

    const sendMail = async (newCollection) => {
        const docRef = await addDoc(collection(firebaseDatabase, "mail"), newCollection);
        if(docRef.id.length > 0) {
            console.debug('mail sent', newCollection);   
        }
    }

    function DeliveryAddressView() {

        return (
            <Paper sx={{p: { xs: 3, md: 3 }}}>
                <Typography variant="h6" component="div">{t('DELIVER_ADDRESS')}</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Controller 
                            rules={{ required: true }}
                            control={control}
                            name={'street'}
                            defaultValue={''}
                            render={({ field }) => (<TextField required {...field} sx={{ width: '100%'}} label={t('STREET')} />)} />
                        
                    </Grid>
                    <Grid item xs={6}>
                        <Controller 
                            rules={{ required: true }}
                            control={control}
                            name={'streetNumber'}
                            defaultValue={''}
                            render={({ field }) => (<TextField required {...field} sx={{ width: '100%'}} label={t('STREET_NUMBER')} />)} />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller 
                            rules={{ required: true }}
                            control={control}
                            name={'zipcode'}
                            defaultValue={zipcode}
                            render={({ field }) => (<TextField required {...field} sx={{ width: '100%'}} disabled={true} label={t('ZIP_CODE')} />)} />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller 
                            rules={{ required: true }}
                            control={control}
                            name={'city'}
                            render={({ field }) => (<TextField required {...field} sx={{ width: '100%'}} label={t('CITY')} />)} />
                    </Grid>
                </Grid>
            </Paper>
        );
    }

    async function OnSubmitHandler(data) {

        if(data.paymentTyp === "Barzahlung"){
            dispatch(updatePersonalDetails(data));
            dispatch(updateDeliveryAddress(data));
            dispatch(updateDeliverytime(data.orderTime));
            
            await initiateCashPaymentTransaction(data);
        }else {

            dispatch(updatePersonalDetails(data));
            dispatch(updateDeliveryAddress(data));
            dispatch(updateDeliverytime(data.orderTime));
            
            createTransactionId(data);
        }
    }

    function DeliveryTimeView() {

        return (
            <Paper sx={{my: { xs: 3, md: 6 },p: { xs: 3, md: 3 }}}>
                <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={1}>
                    <Tooltip title="Öffnungszeit">
                        <IconButton onClick={() => setOpen(true)}>
                            <AccessTimeIcon />
                        </IconButton>
                    </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        {!isBusinessClosed && <Typography variant="h6" component="div">{isDelivery ? t('DELIVER_TIME') : t('PICKUP_TIME')}</Typography>}
                        {isBusinessClosed && <Typography variant="h6" component="div">Momentan keine Lieferung</Typography>}
                    </Grid>
                </Grid>
                <InfoModal isOpen={open} isOpenCallback={(flag) => setOpen(flag)} />
                
                {!isBusinessClosed && <Grid container spacing={2}>
                    <Grid item xs={6}>
                    <Controller 
                        rules={{ required: true }}
                        control={control}
                        name={'orderTime'}
                        defaultValue={''}
                        render={({ field }) => (
                            <FormControl fullWidth required={true}>
                                <InputLabel id="demo-simple-select-label">{isDelivery ? t('DELIVER_TIME') : t('PICKUP_TIME')}</InputLabel>
                                <Select
                                    {...field}
                                    labelId="demo-simple-select-label"
                                    label={isDelivery ? t('DELIVER_TIME') : t('PICKUP_TIME')}
                                >
                                    {timeList && timeList.map((item, index) => <MenuItem key={index} value={item}>{item}</MenuItem>)}
                                </Select>
                            </FormControl>)} />
                        
                    </Grid>
                </Grid>}
                
            </Paper>
        )
    }

    function PaymentMethodView() {
        const paymentMethodList = ['Barzahlung','Visa']; //  , 'MasterCard', 'PostFinance Card', 'PostFinance E-Finance', 'Twint'
        return (
            <Paper sx={{my: { xs: 3, md: 6 },p: { xs: 3, md: 3 }}}>
                <Typography variant="h6" component="div">{t('PAYMENT_WITH')}</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                    <Controller 
                        rules={{ required: true }}
                        control={control}
                        name={'paymentTyp'}
                        defaultValue={''}
                        render={({ field }) => (
                            <FormControl fullWidth required={true}>
                                <InputLabel id="demo-simple-select-label">{t('PAYMENT_METHOD')}</InputLabel>
                                <Select
                                    ref={paymentMethodRef}
                                    {...field}
                                    labelId="demo-simple-select-label"
                                    label={t('PAYMENT_METHOD')}
                                >
                                    {paymentMethodList.map((item, index) => <MenuItem key={index} value={item} defaultValue={item}>{item}</MenuItem>)}
                                </Select>
                            </FormControl>)} />
                    </Grid>
                </Grid>
            </Paper>
        );
    }

    function PersonalDetailsView() {

        return (
            <Paper sx={{my: { xs: 3, md: 6 },p: { xs: 3, md: 3 }}}>
                <Typography variant="h6" component="div">{t('PERSONAL_DATA')}</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                    <Controller 
                        rules={{ required: true, minLength: 3 }}
                        control={control}
                        name={'fullname'}
                        defaultValue={''}
                        render={({ field }) => (<TextField required {...field} type={'text'} sx={{ width: '100%'}} label={t('FULLNAME')} />)} />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller 
                            rules={{ required: true }}
                            control={control}
                            name={'email'}
                            defaultValue={''}
                            render={({ field }) => (<TextField required {...field} type={'email'} sx={{ width: '100%'}} label={t('EMAIL')} />)} />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller 
                                rules={{ required: true }}
                                control={control}
                                name={'tel'}
                                defaultValue={''}
                                render={({ field }) => (<TextField required {...field} type={'tel'} sx={{ width: '100%'}} label={t('TEL_NUMBER')} />)} />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller 
                                rules={{ required: false }}
                                control={control}
                                name={'remarks'}
                                defaultValue={''}
                                render={({ field }) => (<TextField {...field} 
                                    multiline
                                    maxRows={4}
                                    minRows={2}
                                    sx={{ width: '100%'}} label={t('REMARKS')} />)} />
                    </Grid>
                </Grid>
            </Paper>
        );
    }

    return (
        <React.Fragment>
            <AppAppBar />
            <Box sx={{marginTop: 12}}></Box>
            <Container maxWidth="false" sx={{ my: { xs: 3, md: 6 }, p: { xs: 0, md: 0 }}}>
                <Grid container spacing={1.5}>
                    <Grid item sm={2} sx={{ display: {xs: 'none', sm: 'block'}}}></Grid>
                    <Grid item xs={12} sm={7}>
                    <form noValidate onSubmit={handleSubmit(OnSubmitHandler)}>
                        {isDelivery && <DeliveryAddressView />}
                        <PersonalDetailsView />
                        <DeliveryTimeView />
                        <PaymentMethodView />

                        <Button variant="contained" disabled={!isValid || isBusinessClosed} type={'submit'}>{t('PAY', {amount: currency(total)})}</Button>
                    </form>
                        {lightbox && <Lightbox
                        transactionId={transactionId}
                        production={false}
                        onLoaded={onLoaded}
                        onOpened={onOpened}
                        onCancelled={onCancelled}
                        onError={onError}
                    />}
                    </Grid>
                    <Grid item xs={3} sx={{ display: {xs: 'none', sm: 'block'}}}>
                        <BasketView hideActionButton={true} />
                    </Grid>
                </Grid>
            </Container>
            <AppFooter />
          </React.Fragment>);
}

export default withRoot(Checkout);
