import * as React from 'react';
import Box from '@mui/material/Box';

import withRoot from '../withRoot';
import AppAppBar from '../views/AppAppBar';
import AppFooter from '../views/AppFooter';
import { Container } from '@mui/system';
import { Button, Paper, Typography, TextField, Grid } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { firebaseDatabase} from "../firebase-config"; 
import { collection, query, where, limit, getDocs } from "firebase/firestore";  
import { currency } from '../common/formating';

function Receipt() {
    const [printerIPAddress, setPrinterIPAddress] = React.useState("192.168.30.102");
    const [printerPort, setPrinterPort] = React.useState("8008");
    
    const [connectionStatus, setConnectionStatus] = React.useState("");
    const [orderData, setOrderData] = React.useState();

    console.log('orderData', orderData);

    const ePosDevice = React.useRef();
    const printer = React.useRef();

    const STATUS_CONNECTED = "Connected";

    const [searchParams] = useSearchParams();
    const cashPaymentId = searchParams.get('cashPaymentId');

    React.useEffect(() => {

      const ordersRef = collection(firebaseDatabase, "orders");
      const q = query(ordersRef, where("cashPaymentId", "==", cashPaymentId), limit(1));

      getDocs(q).then(querySnapshot => {
          querySnapshot.forEach((doc) => {
            setOrderData(doc.data());
          });
      });
  }, [cashPaymentId])

    const connect = () => {
        setConnectionStatus("Connecting ...");
    
        if (!printerIPAddress) {
          setConnectionStatus("Type the printer IP address");
          return;
        }
        if (!printerPort) {
          setConnectionStatus("Type the printer port");
          return;
        }
    
        setConnectionStatus("Connecting ...");
    
        let ePosDev = new window.epson.ePOSDevice();
        ePosDevice.current = ePosDev;
    
        console.log(ePosDev)
        ePosDev.connect(printerIPAddress, printerPort, (data) => {
          if (data === "OK") {
            ePosDev.createDevice(
              "local_printer",
              ePosDev.DEVICE_TYPE_PRINTER,
              { crypto: true, buffer: false },
              (devobj, retcode) => {
                if (retcode === "OK") {
                  printer.current = devobj;
                  setConnectionStatus(STATUS_CONNECTED);
                } else {
                  throw retcode;
                }
              }
            );
          } else {
            throw data;
          }
        });
      };
    
      const print = () => {
        let prn = printer.current;
        if (!prn) {
          alert("Not connected to printer");
          return;
        }

        const today = new Date();
        const timestamp = today.getDay() + '.' + (today.getMonth() + 1) + '.' + today.getFullYear() + ' ' + today.getHours() + ':' + today.getMinutes();
    
        prn.addTextAlign(prn.ALIGN_CENTER);
        prn.addLogo(48, 48);
        prn.addTextAlign(prn.ALIGN_CENTER);
        prn.addText('Da Samy\n');
        prn.addText('Freiburgstrasse 111,\n 3174 Thörishaus\n');
        prn.addText('Tel.: +41 31 882 12 13\n');
        prn.addText(orderData.cashPaymentId + '\n');
        prn.addText(timestamp + '\n');
        if(orderData.orderTyp === 'DELIVERY'){
          prn.addText('Lieferzeit: ' + orderData.orderTime + '\n');
        }else {
          prn.addText('Abholzeit: ' + orderData.orderTime + '\n');
        }
        prn.addFeedUnit(10);
        prn.addText('**********************\n');
        prn.addTextDouble(true, true);
        if(orderData.orderTyp === 'DELIVERY'){
          prn.addText('Lieferung\n');
        }else {
          prn.addText('Abholung\n');
        }
        prn.addTextDouble(false, false);
        prn.addTextAlign(prn.ALIGN_LEFT);
        prn.addTextStyle(false, false, true, prn.COLOR_1);
        prn.addFeedUnit(20);
        prn.addTextPosition(30);
        prn.addText(orderData.fullname + '\n');
        prn.addTextStyle(false, false, false, prn.COLOR_1);
        prn.addTextPosition(30);
        if(orderData.delivery){
          prn.addText(orderData.delivery.street + ' ' + orderData.delivery.streetNumber + ', ' + orderData.delivery.zip + ' ' + orderData.delivery.city + '\n');
        }
        prn.addTextPosition(30);
        prn.addText(orderData.tel + '\n');
        prn.addFeedUnit(10);
        prn.addTextPosition(30);
        prn.addText('........................................\n');
        
        prn.addTextAlign(prn.ALIGN_LEFT);
        prn.addTextPosition(30);
        prn.addTextStyle(false, false, false, prn.COLOR_1);
        prn.addText('Bezahlungsart: ' + (orderData.paymentTyp === 'Barzahlung' ? 'Barzahlung' : 'Online') +'\n');

        prn.addTextAlign(prn.ALIGN_LEFT);
        prn.addTextPosition(30);
        prn.addTextStyle(false, false, false, prn.COLOR_1);
        if(orderData.remarks.length > 0){
          prn.addText('Bemerkung:\n');
          prn.addTextPosition(30);
          prn.addText(orderData.remarks + '\n');
        }
        prn.addFeedUnit(10);
        prn.addTextPosition(30);
        prn.addText('........................................\n');
        prn.addFeedUnit(0);
        for (let i = 0; i < orderData.items.length; i++) {
          const element = orderData.items[i];
          
          prn.addTextPosition(30);
          prn.addText(element.count + 'x   ' + element.item.item.name +'\t ' + currency(element.total)+'\n');

          for (let x = 0; x < element.selectedAdditionals.length; x++) {
            const additionalElement = element.selectedAdditionals[x];
            
            prn.addText('-       ' + element.item.additionals[additionalElement.additionalIdx].values[additionalElement.valueIdx].title + '  (' + currency(additionalElement.extraAmount)+') \n');
          }

        }
        
        //prn.addTextPosition(30);
        //prn.addText('1x\t Caramelita\t\t 6.50 CHF\n');

        prn.addTextPosition(30);
        prn.addText('----------------------------------------\n');
        prn.addTextPosition(30);
        prn.addText('Lieferkosten\t\t\t ' + currency(0) + '\n');
        prn.addTextPosition(30);
        prn.addText('Total\t\t\t\t ' + currency(orderData.total) + '\n');
        prn.addTextPosition(30);
        prn.addText('........................................\n');
        prn.addFeedUnit(30);
        prn.addTextStyle(false, true, false, prn.COLOR_1);
        prn.addTextAlign(prn.ALIGN_CENTER);
        prn.addText('Wichtig:\n');
        prn.addTextStyle(false, false, true, prn.COLOR_1);
        if(orderData.paymentTyp === 'Barzahlung'){
          prn.addText('Bestellung wurde NICHT online bezahlt\n');
        }else {
          prn.addText('Bestellung wurde online bezahlt\n');
        }
        
        prn.addTextLineSpace(30);
        prn.addCut(prn.CUT_FEED);
        prn.send();
      };

    return (
        <React.Fragment>
            <AppAppBar />
            <Box sx={{marginTop: 12}}></Box>
            <Container maxWidth="sm" sx={{ my: { xs: 3, md: 6 }, p: { xs: 0, md: 0 }}}>
                <Paper sx={{my: { xs: 3, md: 6 },p: { xs: 3, md: 3 }}}>
                <Typography align='center'>Drucken</Typography>
                <Box sx={{marginTop: 4}}></Box>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                  <TextField id="ipaddress" label="Drucker IP-Adresse" placeholder="Printer IP Address" variant="outlined" defaultValue={printerIPAddress}
                    onChange={(e) => setPrinterIPAddress(e.currentTarget.value)} />
                </Grid>
                <Grid item xs={6}>
                  <TextField id="printerPort" label="Drucker Port" placeholder="Printer Port" variant="outlined" 
                    value={printerPort}
                    onChange={(e) => setPrinterPort(e.currentTarget.value)} />
                </Grid>
                <Grid item xs={6}>
                <Button disabled={connectionStatus === STATUS_CONNECTED}
                      onClick={() => connect()} variant='contained'>Verbinden</Button>
                </Grid>
              </Grid> 
                
              <span className="status-label">{connectionStatus}</span>
              <hr />

                <Button disabled={connectionStatus !== STATUS_CONNECTED}
                      onClick={() => print()} variant='contained'>Drucken</Button>
                </Paper>
            </Container>
            <AppFooter />
          </React.Fragment>);
}

export default withRoot(Receipt);
