import * as React from 'react';
import Box from '@mui/material/Box';

import withRoot from '../withRoot';
import AppAppBar from '../views/AppAppBar';
import AppFooter from '../views/AppFooter';
import { Container } from '@mui/system';
import { Paper, Typography } from '@mui/material';

function NotFound() {

    return (
        <React.Fragment>
            <AppAppBar />
            <Box sx={{marginTop: 12}}></Box>
            <Container maxWidth="sm" sx={{ my: { xs: 3, md: 6 }, p: { xs: 0, md: 0 } }}>
                <Paper sx={{my: { xs: 3, md: 6 },p: { xs: 3, md: 3 }}}>
                    <Typography  variant="h3" align='center'>Die gesuchte Seite existiert nicht.</Typography>
                </Paper>
            </Container>
            <AppFooter />
          </React.Fragment>);
}

export default withRoot(NotFound);
