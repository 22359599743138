import { createSlice } from '@reduxjs/toolkit'
import deliveryZone from './../deliveryzone.json'

const initialState = {
  orderType: 'PICKUP',
  isDelivery: false,
  zipcode: null,
  minimumOrderValue: 0
};

const deliverySlice = createSlice({
    name: 'delivery',
    initialState: initialState,
    reducers: {
      updateOrderType: (state, action) => {
        const data = action.payload;
        state.orderType = data;
        state.isDelivery = data === 'DELIVERY';

        if(!state.isDelivery){
          state.zipcode = null;
          state.minimumOrderValue = null;
        }
      },
      updateZipcode: (state, action) => {
        const data = action.payload;
        var zone = deliveryZone.find( x => x.zipcode === parseInt(data));

        state.zipcode = zone.zipcode;
        state.minimumOrderValue = zone.minimumOrderValue;
      },
      updateMinimumOrderValue: (state, action) => {
        const data = action.payload;
        state.minimumOrderValue = data;
      }
    }
  });
  
  export const { updateOrderType, updateZipcode, updateMinimumOrderValue } = deliverySlice.actions
  export default deliverySlice.reducer