import * as React from 'react';
import ProductHomeLayout from './ProductHomeLayout';

export default function ProductHome() {
  return (
    <ProductHomeLayout
      sxBackground={{
        backgroundImage: `url('/img/backgroundimage-test.jpg')`,
        backgroundColor: '#7fc7d9', // Average color of the background image.
        backgroundPosition: 'center',
      }}
    >
    </ProductHomeLayout>
  );
}

