import * as React from 'react';
import { useSelector } from 'react-redux'
import Chip from '@mui/material/Chip';
import { Container } from '@mui/material';
import { Stack } from '@mui/system';
import Typography from '@mui/material/Typography';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Divider from '@mui/material/Divider';
import { useTranslation } from "react-i18next";

function ProductCategories(){
    const { t } = useTranslation();

    const products = useSelector((state) => state.products.items);
    const [showCategory, SetShowCategory] = React.useState(false);

    return (<Container maxWidth={false} spacing={1} sx={{marginBottom: 5, borderBottom: (theme) => `1px solid ${theme.palette.divider}`, backgroundColor: 'white'}} >
                <Stack sx={{ display: {xs: 'none', sm: 'block'}}}>
                    {Object.keys(products).map((key, index) => <Chip sx={{ m: { xs: 1, md: 1 } }} key={index} label={key} variant="outlined" clickable={true} onClick={() => document.getElementById(encodeURI(key))?.scrollIntoView({ behavior: 'smooth' })} />)}
                </Stack>
                <Stack sx={{minHeight: 50, width: 100, display: {xs: 'block', sm: 'none'}}}>
                <Stack
                    direction="row"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                    >
                    <MenuOpenIcon
                                size="large"
                                edge="start"
                                color="inherit"
                                onClick={() => SetShowCategory(!showCategory)}
                                aria-label="open drawer"
                            />
                    <Typography variant="h6" component="div" sx={{ textAlign: 'center', verticalAlign: 'middle' }} onClick={() => SetShowCategory(!showCategory)}>
                        {t('CATEGORY')}
                    </Typography>
                </Stack>
                    
                {showCategory && 
                <Stack sx={{minWidth: ' fit-content', alignContent: 'center'}}>
                    {Object.keys(products).map((key, index) => <Chip sx={{ m: { xs: 1, md: 1 } }} key={index} label={key} variant="outlined" clickable={true} onClick={() => {document.getElementById(encodeURI(key))?.scrollIntoView({ behavior: 'smooth' }); SetShowCategory(!showCategory)}} />)}
                </Stack>}
                </Stack>
            </Container>);
}

export default ProductCategories;