import * as React  from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import FormHelperText from '@mui/material/FormHelperText';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { addOrRemovePreBasket, increasePreBasketCount, decreasePreBasketCount } from '../features/productsSlice';
import { addToBasket } from '../features/basketSlice';
import { currency } from '../common/formating';

import { Controller, useForm } from "react-hook-form";

  const ControlsView = React.forwardRef((props, ref) => {
    const { t } = useTranslation();

    const { handleSubmit, control, trigger, formState: { errors, isValid } } = useForm();
    const dispatch = useDispatch();
    const detail = useSelector((state) => state.products.itemDetail);

    function handleFormSubmit(additionalIndex, valueoptionIndex, extraAmount, isCheckbox){
        const data = { additionalIdx: additionalIndex, 
                    valueIdx: valueoptionIndex,
                    extraAmount: extraAmount,
                    isCheckboxTyp: isCheckbox
                };
        
        dispatch(addOrRemovePreBasket(data))
    }

    React.useImperativeHandle(ref, () => ({
        async triggerFormValidation() {
            return await trigger(); 
        }
      }));

    return (
    <form noValidate onSubmit={handleSubmit(data => console.log(isValid))}>
    <Stack>
    {detail.additionals.map((extra, additionalIndex) => {
      if(extra.typ === 'CHECKBOX')
      {
        return (
          <FormControl key={'form-ctl-key-'+additionalIndex} required={extra.required}
                       error={errors['form-ctl-input-checkbox--'+additionalIndex] !== undefined} 
                       component="fieldset">
            <FormLabel component="legend">{extra.title}</FormLabel>
            <Controller 
                rules={{ required: extra.required }}
                control={control}
                name={'form-ctl-input-checkbox--'+additionalIndex}
                render={({ field }) => (
                    <>
                    {extra.values.map((v, valueoptionIndex) =>
                    <Grid container key={'key'+valueoptionIndex}>
                        <Grid item xs={9}>
                            <FormControlLabel
                                {...field}
                                value={v.value}
                                control={<Checkbox  onChange={e => handleFormSubmit(additionalIndex, valueoptionIndex, v.extraAmount, true)}  />}
                                label={v.title}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            {extra.isFree === false && 
                            <Typography variant="body2" color="text.secondary" sx={{verticalAlign: 'end'}}>
                                {currency(v.extraAmount)}
                            </Typography>}
                        </Grid>
                    </Grid>
                    )}
                    </>
                )}  />
                {errors['form-ctl-input-checkbox--'+additionalIndex]?.type === 'required' && <FormHelperText>{t('PLEASE_SELECT')}</FormHelperText>}
          </FormControl>
        )
      } else {
        return (
          <FormControl key={'form-ctl-key-'+additionalIndex} required={extra.required} 
                       error={errors['form-ctl-input-radio--'+additionalIndex] !== undefined} 
                       component="fieldset">
            <FormLabel component="legend">{extra.title}</FormLabel>
            <Controller 
                rules={{ required: extra.required }}
                control={control}
                defaultValue={''}
                name={'form-ctl-input-radio--'+additionalIndex}
                render={({ field }) => (
                    <RadioGroup 
                    {...field}
                    >
                    {extra.values.map((v, valueoptionIndex) =>
                    <Grid container key={'key'+valueoptionIndex}>
                        <Grid item xs={9}>
                            <FormControlLabel
                                onChange={e => handleFormSubmit(additionalIndex, valueoptionIndex, v.extraAmount, false)}
                                value={v.value}
                                control={<Radio />}
                                label={v.title}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            {extra.isFree === false && 
                            <Typography variant="body2" color="text.secondary" sx={{verticalAlign: 'end'}}>
                                {currency(v.extraAmount)}
                            </Typography>}
                        </Grid>
                    </Grid>
                    )}
                    </RadioGroup>
                )}  />
                {errors['form-ctl-input-radio--'+additionalIndex]?.type === 'required' && <FormHelperText>{t('PLEASE_SELECT')}</FormHelperText>}
          </FormControl>
        )
      }
    })}
    </Stack>
    </form>);
});

function ProductItemDetailModal(props) {

    const dispatch = useDispatch();
    const detail = useSelector((state) => state.products.itemDetail);
    const preBasketCount = useSelector((state) => state.products.preBasketCount);
    const preBasket = useSelector((state) => state.products.preBasket);

    React.useEffect(() => {
        setOpenModal(props.isOpen);
    }, [props.isOpen])

    const [openModal, setOpenModal] = React.useState(false);

    const productItemSubtotal = (basePrice, items) => {
        let sum = basePrice;
  
        items.forEach(item => {
            sum += item.extraAmount;
        });
    
        return (sum * preBasketCount);
    }

    const handleAddToBasket = async () => {

        const isValid = await controlViewRef.current.triggerFormValidation();

        if(!isValid){
            return;
        }

        const item = {
            item: detail,
            selectedAdditionals: preBasket,
            count: preBasketCount,
            total: productItemSubtotal(detail.item.basePrice, preBasket)
        }

        dispatch(addToBasket(item));
        handleCloseModal();
    }


    const handleCloseModal = () => {
        setOpenModal(false);
        props.isOpenCallback(false);
    };

    const controlViewRef = React.useRef();
    
    return (<Modal
    open={openModal}
    onClose={handleCloseModal}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    >
    <Box sx={{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: 400, md: 1000},
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
  }}>
        <Grid container spacing={1.5}>
            <Grid item xs={4} sx={{ display: {xs: 'none', sm: 'block'}}}> 
                <img src={detail.item.imageUrl} max-width={300} height={200} alt="img" />
            </Grid>
            <Grid item xs={12} md={8}>
                <Stack maxHeight={500} sx={{overflow: 'auto'}}>
                    <Stack direction="row" spacing={2}>
                        <Typography id="modal-modal-title">
                        {detail.item.name}
                        </Typography>
                        <Typography id="modal-modal-title">
                        {currency(detail.item.basePrice)}
                        </Typography>
                    </Stack>

                    <Typography variant="body2" color="text.secondary" >
                        {detail.item.description}
                    </Typography>

                    <Stack>
                        <ControlsView ref={controlViewRef} />
                    </Stack>
                </Stack>
                
                <Stack>
                    <Stack direction="row" spacing={2} sx={{marginTop: 2}}>
                        <IconButton aria-label="add" size="large" onClick={() => dispatch(increasePreBasketCount())}>
                            <AddIcon />
                        </IconButton>
                        <Typography variant="subtitle1">{preBasketCount}</Typography>
                        <IconButton disabled={preBasketCount === 1} aria-label="delete" size="large" onClick={() => dispatch(decreasePreBasketCount())}>
                            <RemoveIcon />
                        </IconButton>
                        <Button size="large" variant="contained" color="primary" onClick={() => handleAddToBasket()}>
                            {currency(productItemSubtotal(detail.item.basePrice, preBasket))}
                        </Button>
                    </Stack>
                </Stack>
            </Grid>
        </Grid>
    </Box>
    </Modal>);
}

export default ProductItemDetailModal;