import * as React from 'react';
import Box from '@mui/material/Box';

import withRoot from '../withRoot';
import AppAppBar from '../views/AppAppBar';
import AppFooter from '../views/AppFooter';
import { Container } from '@mui/system';
import { useSearchParams } from 'react-router-dom';

import axios from 'axios';

function CheckoutError() {

    const [searchParams] = useSearchParams();
    const datatransTrxId = searchParams.get('datatransTrxId');

    React.useEffect(() => {
        if(datatransTrxId === null || datatransTrxId === undefined || datatransTrxId?.length === 0)
            return;

        axios.get('https://api.sandbox.datatrans.com/v1/transactions/'+datatransTrxId, {
            auth: {
                username: "1110003085",
                password: "39XDRUkMOdanXnr1"
            }
        }).then(res => {
            if(res.data.status === 'settled' || res.data.status === 'transmitted'){
                // submitOrder
            }else {
                // show error and try again
            }
            console.log('response', res);
        }).catch((err) => {
          console.log(err);
        })
    }, [datatransTrxId]);


    return (
        <React.Fragment>
            <AppAppBar />
            <Box sx={{marginTop: 12}}></Box>
            <Container maxWidth="false" sx={{ my: { xs: 3, md: 6 }, p: { xs: 0, md: 0 }}}>
                Error Page
            </Container>
            <AppFooter />
          </React.Fragment>);
}

export default withRoot(CheckoutError);
