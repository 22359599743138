import { configureStore, combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit'
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'reduxjs-toolkit-persist';
import storage from 'reduxjs-toolkit-persist/lib/storage'
import autoMergeLevel1 from 'reduxjs-toolkit-persist/lib/stateReconciler/autoMergeLevel1';

import basketSlice from './features/basketSlice'
import orderSlice from './features/orderSlice'
import productsReducer from './features/productsSlice'
import deliverySlice from './features/deliverySlice';

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['basket', 'delivery', 'products', 'order'],
  stateReconciler: autoMergeLevel1,
}

const basketPersistConfig = {
  key: 'basket',
  storage: storage
  //blacklist: ['auth']
}


const deliveryPersistConfig = {
  key: 'delivery',
  storage: storage
  //blacklist: ['auth']
}

const reducers = combineReducers({
  products: productsReducer,
  order: orderSlice,
  delivery: persistReducer(deliveryPersistConfig, deliverySlice),
  basket: persistReducer(basketPersistConfig, basketSlice)
});

export const store = configureStore({
    reducer: persistReducer(rootPersistConfig, reducers),
    middleware: getDefaultMiddleware({
      serializableCheck: {
        /* ignore persistance actions */
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER
        ],
      },
    }),
})

export const persistor = persistStore(store);