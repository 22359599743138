import * as React from 'react';
import Stack from '@mui/material/Stack';
import { useDispatch, useSelector } from 'react-redux'
import { toggleFilter } from '../features/productsSlice';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { Paper } from '@mui/material';
import { useTranslation } from "react-i18next";

function ProductFilter(){
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const filters = useSelector((state) => state.products.filters);

    return (<Paper elevation={1} sx={{minHeight: '100%', p: { xs: 3, md: 3 }, display: {xs: 'none', sm: 'block'}}}>
                <Stack sx={{position: 'sticky', top: '130px'}}>
                    <Typography variant="body2" color="text.secondary" >
                        {t('FILTER_BY')}
                    </Typography>
                    {filters?.map((x, i) => 
                    <Stack key={i} direction="row" spacing={2}>
                        <FormControlLabel key={i} value={x} control={<Checkbox />} onChange={(e) => dispatch(toggleFilter(e.target.value))} label={x} />
                    </Stack>)}
                </Stack>
            </Paper>); 
}

export default ProductFilter;