import * as React  from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { useTranslation } from "react-i18next";
import deliveryTime from './../deliverytime.json';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
  };

function InfoModal(props) {
    const { t } = useTranslation();

    React.useEffect(() => {
        setOpenModal(props.isOpen);
    }, [props.isOpen])

    const [openModal, setOpenModal] = React.useState(false);

    const handleCloseModal = () => {
        setOpenModal(false);
        props.isOpenCallback(false);
    };

    return (<Modal open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description">
        <Box sx={style}>
            <Typography variant="h6" component="div">{t('DELIVER_TIME')}</Typography>
            <Grid container spacing={2}>
            {deliveryTime.map(it => {
                return (
                <Grid key={it.day} item xs={12}>
                    {it.day.toUpperCase() === Date().toLocaleString().split(' ')[0].toUpperCase() ? <Typography sx={{fontWeight: 'bold'}}>{it.displayDay}</Typography> : <Typography>{it.displayDay}</Typography>}
                    {it.times.length === 0 && <Typography>Momentan keine Lieferung</Typography>}
                    {it.times.map(t => {
                        return (<Stack key={t.startTime} direction="column" spacing={2}>
                        <Typography>{t.startTime} - {t.endTime}</Typography>
                    </Stack>)
                    })}
                </Grid>)
            })}
            </Grid>
        </Box>
    </Modal>);
}

export default InfoModal;