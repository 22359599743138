import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  basket: [],
  subtotal: 0
};


const basketSlice = createSlice({
    name: 'basket',
    initialState: initialState,
    reducers: {
      addToBasket: (state, action) => {
        const data = action.payload;
        state.basket.push(data);

        let subtotal = 0;
        state.basket.forEach(item => {
          subtotal += item.total 
        });

        state.subtotal = subtotal;
      },
      removeItemFromBasekt: (state, action) => {
        state.basket.splice(action.payload, 1);

        let subtotal = 0;
        state.basket.forEach(item => {
          subtotal += item.total 
        });

        state.subtotal = subtotal;
      },
      removeAllFromBasket: (state) => {
        state.basket = [];
        state.subtotal = 0;
      }
    }
  });
  
  export const { addToBasket,removeItemFromBasekt, removeAllFromBasket } = basketSlice.actions
  export default basketSlice.reducer